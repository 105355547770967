import { Field, useFormikContext } from 'formik';
import type { FormikValues } from 'formik';
import zip from 'lodash/zip';
import React from 'react';

import { Trans } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import AutoFixHighIcon from '@mui/icons-material/AutoFixHigh';
import { LoadingButton } from '@mui/lab';
import { Grid, Typography } from '@mui/material';
import MuiAlert from '@mui/material/Alert';

import ConfirmationModal from 'src/components/common/confirmationModal';
import DynamicList from 'src/components/common/dynamicList';
import type { TriggerButtonProps } from 'src/components/common/modal';
import { Bucket, BucketContainer } from 'src/components/sections/bucketSection';
import CardSection from 'src/components/sections/cardSection';
import { InternalSection } from 'src/components/sections/section';
import ErrorMessage from 'src/components/typography/errorMessage';
import { useAppDispatch } from 'src/redux/hooks';

import DisclaimerAIModal from './disclaimerAIModal';
import GenerateStyledSwitch from './generateStyledSwitch';
import { useSuggestions, useSuggestionsStatusIsLoading } from './state/hooks';
import { removeSuggestion } from './state/reducer';
import TextareaWithSuggestion from './textareaWithSuggestion';
import TitledTextareaWithSuggestion from './titledTextareaWithSuggestion';

interface ProjectOutlineFormProps {
    refineObjectives: () => void;
    generateSuggestions: () => void;
    handleApplyAll: (values: FormikValues, onChange) => void;
}

const ProjectOutlineForm = ({
    refineObjectives,
    generateSuggestions,
    handleApplyAll,
}: ProjectOutlineFormProps) => {
    const { i18n } = useLingui();
    const dispatch = useAppDispatch();
    const suggestions = useSuggestions();

    const isLoading = useSuggestionsStatusIsLoading();
    const [enableAI, setEnableAI] = React.useState(true);

    const { values, setFieldValue, setValues } = useFormikContext() as any;

    const createSuggestionTitles =
        suggestions?.projectDeliverables?.length > 0 || suggestions?.projectObjectives?.length > 0
            ? i18n._('Re-create all suggestions')
            : i18n._('Create Suggestions');

    const triggerButtonComponent = React.useCallback(
        (handleOpenClick) => (
            <LoadingButton
                data-testid="generateSuggestions"
                loading={isLoading}
                color="primary"
                startIcon={<AutoFixHighIcon />}
                onClick={handleOpenClick}
                variant="contained"
                sx={{ ml: 2 }}
            >
                {createSuggestionTitles}
            </LoadingButton>
        ),
        [createSuggestionTitles, isLoading],
    );

    const refineObjectivesButton = React.useCallback(
        (handleOpenClick) => (
            <LoadingButton
                data-testid="refineObjectives"
                loading={isLoading}
                color="primary"
                startIcon={<AutoFixHighIcon />}
                onClick={handleOpenClick}
                variant="contained"
                sx={{ ml: 2 }}
            >
                <Trans>Refine Objectives</Trans>
            </LoadingButton>
        ),
        [createSuggestionTitles, isLoading],
    );

    const applyAllHandler = React.useCallback(() => {
        handleApplyAll(values, setValues);
    }, [values, setValues, handleApplyAll]);

    const ApplyAllButton = ({ handleOpen }: TriggerButtonProps) => (
        <LoadingButton
            startIcon={<ArrowBackIosIcon />}
            disabled={
                isLoading ||
                !(
                    suggestions.projectDeliverables?.length > 0 ||
                    suggestions.projectObjectives?.length > 0
                )
            }
            color="primary"
            onClick={handleOpen}
            variant="contained"
        >
            {i18n._('Apply all')}
        </LoadingButton>
    );

    const headerActions = (
        <Grid display="flex" alignItems="center" justifyContent="flex-end">
            <ConfirmationModal
                triggerButtonComponent={enableAI ? ApplyAllButton : () => null}
                onConfirm={applyAllHandler}
                description={i18n._(
                    'Are you sure you want to apply all suggestions? Your current changes will be lost!',
                )}
                confirmButtonText={i18n._('Confirm')}
                cancelButtonText={i18n._('Cancel')}
            />
            {enableAI && (
                <DisclaimerAIModal
                    onConfirm={generateSuggestions}
                    triggerButtonComponent={triggerButtonComponent}
                />
            )}
            <Grid display="flex" alignItems="center">
                {!enableAI && <Typography variant="h6">{i18n._('Enable AI features')}</Typography>}
                <GenerateStyledSwitch
                    sx={{}}
                    onChange={() => setEnableAI(!enableAI)}
                    checked={enableAI}
                />
            </Grid>
        </Grid>
    );

    const initialSituationHeader = (
        <BucketContainer isVertical={false}>
            <Bucket xs={7} withDivider={false}>
                <Typography variant="h6">
                    <Trans>Initial Situation</Trans>
                </Typography>
            </Bucket>
            {enableAI && (
                <Bucket xs={5}>
                    <Typography variant="h6">
                        <Trans>AI Suggestions</Trans>
                    </Typography>
                </Bucket>
            )}
        </BucketContainer>
    );

    const dynamicListOnRemoveProjectDeliverables = React.useCallback(
        (index) =>
            dispatch(
                removeSuggestion({
                    suggestionType: 'projectDeliverables',
                    index,
                }),
            ),
        [dispatch],
    );

    const dynamicListOnChangeProjectDeliverables = React.useCallback(
        (updatedValue) =>
            setFieldValue(
                'projectDeliverables',
                updatedValue.map((item) => item[0]),
            ),
        [setFieldValue],
    );

    const dynamicListOnRemoveProjectObjectives = React.useCallback(
        (index) =>
            dispatch(
                removeSuggestion({
                    suggestionType: 'projectObjectives',
                    index,
                }),
            ),
        [dispatch],
    );

    const dynamicListOnChangeProjectObjectives = React.useCallback(
        (updatedValue) =>
            setFieldValue(
                'projectObjectives',
                updatedValue.map((item) => item[0]),
            ),
        [setFieldValue],
    );

    const dynamicListRenderItemProjectDeliverable = React.useCallback(
        (item, index) => (
            <TitledTextareaWithSuggestion
                loading={isLoading}
                entry={item[0]}
                suggestion={item[1]}
                showSuggestions={enableAI}
                onChange={(updatedValue) => {
                    const updatedValues = [...values.projectDeliverables];
                    updatedValues[index] = updatedValue;
                    setFieldValue('projectDeliverables', updatedValues);
                }}
            />
        ),
        [isLoading, setFieldValue, enableAI, values.projectDeliverables],
    );

    const dynamicListRenderItemProjectObjectives = React.useCallback(
        (item, index) => (
            <TitledTextareaWithSuggestion
                loading={isLoading}
                entry={item[0]}
                suggestion={item[1]}
                showSuggestions={enableAI}
                onChange={(updatedValue) => {
                    const updatedValues = [...values.projectObjectives];
                    updatedValues[index] = updatedValue;
                    setFieldValue('projectObjectives', updatedValues);
                }}
            />
        ),
        [isLoading, setFieldValue, enableAI, values.projectObjectives],
    );

    return (
        <CardSection title={i18n._('Project Outline')} actions={headerActions} id="project-outline">
            <Grid container direction="column">
                {isLoading && (
                    <Grid xs={12} sm={5} alignSelf="flex-end">
                        <Grid>
                            <MuiAlert severity="info">
                                {i18n._(
                                    'Toni from apadua is browsing huge libraries of books, online content, and apadua’s proprietary content to create suggestions for you. This is a lot of work. As this may take 1-2 minutes. Just lay back and relax.',
                                )}
                            </MuiAlert>
                        </Grid>
                    </Grid>
                )}
                <Grid container direction="row">
                    <Field name="initialSituation" type="text">
                        {({ field, meta }) => (
                            <InternalSection
                                title={initialSituationHeader}
                                tooltipText={i18n._(
                                    'Describe in a few sentences the situation of your company before this project.',
                                )}
                                header={<ErrorMessage errorMessage={meta.touched && meta.error} />}
                            >
                                <TextareaWithSuggestion
                                    loading={isLoading}
                                    entry={field.value}
                                    suggestion={suggestions.initialSituation}
                                    showSuggestions={enableAI}
                                    name="initialSituation"
                                    error={meta.touched && !!meta.error}
                                    onChange={(text) => setFieldValue('initialSituation', text)}
                                />
                            </InternalSection>
                        )}
                    </Field>
                </Grid>
                <Grid sx={{ mt: 2 }} container direction="row">
                    <Field name="projectApproach">
                        {({ field, meta }) => (
                            <InternalSection
                                title={i18n._('Project Approach')}
                                tooltipText={i18n._(
                                    'Describe the planned project approach or methodology in a few sentences.',
                                )}
                                header={<ErrorMessage errorMessage={meta.touched && meta.error} />}
                            >
                                <TextareaWithSuggestion
                                    loading={isLoading}
                                    entry={field.value}
                                    error={meta.touched && !!meta.error}
                                    suggestion={suggestions.projectApproach}
                                    showSuggestions={enableAI}
                                    name="projectApproach"
                                    onChange={(text) => setFieldValue('projectApproach', text)}
                                />
                            </InternalSection>
                        )}
                    </Field>
                </Grid>
                <Grid sx={{ mt: 2 }} container direction="row">
                    <Field id="projectObjectives" name="projectObjectives">
                        {({ field, meta }) => (
                            <InternalSection
                                title={i18n._('Objectives')}
                                header={
                                    <>
                                        <ErrorMessage
                                            errorMessage={
                                                meta.touched &&
                                                !!meta.error &&
                                                i18n._('At least one project objective is required')
                                            }
                                        />
                                        {enableAI && (
                                            <DisclaimerAIModal
                                                onConfirm={refineObjectives}
                                                triggerButtonComponent={refineObjectivesButton}
                                            />
                                        )}
                                    </>
                                }
                                tooltipText={i18n._(
                                    'Select the project objectives or add your own objectives and describe them.',
                                )}
                            >
                                <DynamicList
                                    data-testid="projectObjectivesContainer"
                                    items={zip(field.value, suggestions.projectObjectives)}
                                    emptyItem={[{ title: '', description: '' }, {}]}
                                    onRemove={dynamicListOnRemoveProjectObjectives}
                                    onChange={dynamicListOnChangeProjectObjectives}
                                    renderItem={dynamicListRenderItemProjectObjectives}
                                />
                            </InternalSection>
                        )}
                    </Field>
                </Grid>
                <Grid sx={{ mt: 2 }} container direction="row">
                    <Field id="projectDeliverables" name="projectDeliverables">
                        {({ field, meta }) => (
                            <InternalSection
                                title={i18n._('Project Deliverables')}
                                tooltipText={i18n._(
                                    'List the desired specific tangible and intangible project deliverables.',
                                )}
                                header={
                                    <ErrorMessage
                                        errorMessage={
                                            meta.touched &&
                                            !!meta.error &&
                                            i18n._('At least one project goal is required')
                                        }
                                    />
                                }
                                sx={{}}
                            >
                                <DynamicList
                                    data-testid="projectDeliverablesContainer"
                                    items={zip(field.value, suggestions.projectDeliverables)}
                                    emptyItem={[{ title: '', description: '' }, {}]}
                                    onRemove={dynamicListOnRemoveProjectDeliverables}
                                    onChange={dynamicListOnChangeProjectDeliverables}
                                    renderItem={dynamicListRenderItemProjectDeliverable}
                                />
                            </InternalSection>
                        )}
                    </Field>
                </Grid>
            </Grid>
        </CardSection>
    );
};

export default ProjectOutlineForm;
